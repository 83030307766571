import * as types from './auth.types';

const initialState = {
  email: null,
  userToken: null,
  refreshToken: null,
  locale: process.env.REACT_APP_DEFAULT_LANG,
  tokenRefreshState: 'done',
};

const auth = (state = initialState, action) => {
  switch (action.type) {
  case types.LOGIN: {
    return action.payload;
  }

  case types.REFRESH_REQUESTED: {
    return {
      ...state,
      tokenRefreshState: state.tokenRefreshState === 'loading'
        ? state.tokenRefreshState : 'requested',
    };
  }

  case types.REFRESH_LOADING: {
    return {
      ...state,
      tokenRefreshState: 'loading',
    };
  }

  case types.REFRESH_FAILED: {
    return {
      ...state,
      tokenRefreshState: 'failed',
    };
  }

  case types.REFRESH_DONE: {
    return {
      ...state,
      ...action.payload,
      tokenRefreshState: 'done',
    };
  }

  case types.LOGOUT: {
    return { ...initialState };
  }

  default:
    return state;
  }
};

export default auth;

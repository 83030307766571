import styled, { css } from 'styled-components/macro';

export const InputContainer = styled.div`
  font-size: 0.85rem;
  position: relative;
  width: 100%;
  button {
    cursor: pointer;
    position: absolute;
    top: 0.625rem;
    right: 1rem;
    border: none;
    background: none;
  }
`;

export const FileInputWrapper = styled.div`
  height: 119px;
  border-radius: 4px;
  border: 1px dashed var(--clay-500, #AAA);
  background: var(--White, #FFF);
  background: white;
  padding-top: 14px;
  padding-left: 16px;
  color: var(--blue-300, #1854D9);
  cursor: pointer;

`;

export const FileInputRequirementsLine = styled.div`
  color: var(--clay-500, #AAA);
  /* Body S/Regular */
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
`;

export const FileStrip = styled.div`
  border-radius: 8px;
  border: 1px solid var(--clay-300, #EAEAEA);
  background: var(--White, #FFF);
  display: flex;
  padding: 15px 16px;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  display: flex; 
  justify-content: space-between;
  button {
    border: none;
    background: none;
    height: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

export const Input = styled.input`
  font-size: 0.75rem;
  padding: 0.625rem 1rem;
  border: 1px solid ${({ theme }) => theme.palette?.clay[300] || '#EAEAEA'};
  border-radius: 4px;
  width: 100%;
  min-height: 40px;
  color: ${({ theme }) => theme.palette?.clay[800] || '#aaa'};
  background-color: white;

  :focus, :hover {
    border: 1px solid rgba(24, 84, 217, 0.3);
  }
  :disabled {
    border-color: transparent;
  }

  ${({ error, type }) => error
    && type !== 'submit'
    && css`
      border-bottom: 3px solid #ff4d03;
    `};
  ${({ type }) => type === 'password'
    && css`
      padding-right: 2.1rem;
    `};
  ${({ error }) => error
    && css`
      color: #ec0a0a;
    `};
`;

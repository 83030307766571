import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  height: 64px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding-right: 32px;
  position: relative;
  z-index: 101;
`;

export const Cta = styled.div`
  background-color: ${({ theme }) => theme.palette.purple[500]};
  color: #fff;
  padding: 12px 28px;
  border-radius: 4px;
  align-self: center;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 40px;
  font-size: 0.75rem;
`;

export const CtaRow = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  gap: 24px;
  a {
    font-family: "ReadexPro";
  }
`;

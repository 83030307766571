import React from 'react';
import ScrollLock from 'react-scrolllock';
import styled from 'styled-components';

import { ContentLoader } from 'appComponents/ContentLoader';

export const LoaderStyled = styled.div<{ size?: 'sm' | 'lg' }>`
  &,
  &:after {
    border-radius: 50%;
    width: ${({ size = 'lg' }) => (size === 'sm' ? '16px' : '68px')};
    height: ${({ size = 'lg' }) => (size === 'sm' ? '16px' : '68px')};
  }
  margin: ${({ size = 'lg' }) => (size === 'sm' ? 'auto' : '20px auto')};
  font-size: 10px;
  position: relative;
  border-top: 3px solid #eaeaea;
  border-right: 3px solid #eaeaea;
  border-bottom: 3px solid #eaeaea;
  border-left: 3px solid ${({ theme }) => theme.palette.blue[300]};
  border-width: ${({ size = 'lg' }) => (size === 'sm' ? '3px' : '10px')};
  transform: translateZ(0);
  animation: load8 0.7s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const LoaderWrapperStyled = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100vh - 64px);
  width: 100vw;
  display: flex;
  align-items: center;
  z-index: 100;
  background-color: hsl(0, 9.1%, 97.8%);
`;

export const Loader: React.FC<{
  size?: 'sm' | 'lg';
  type?: 'content' | 'spinner';
  lines?: number;
  noPadding?: boolean;
}> = ({
  size = 'lg', type = 'spinner', lines = 3, noPadding = false,
}) => {
  if (size === 'sm') {
    return <LoaderStyled size={size} />;
  }

  if (type === 'content') {
    return <ContentLoader lines={lines} noPadding={noPadding} />;
  }

  return (
    <LoaderWrapperStyled className="loader">
      <ScrollLock />
      <LoaderStyled size={size} />
    </LoaderWrapperStyled>
  );
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { useSWRConfig } from 'swr';

import * as authActions from 'state/auth/auth.actions';
import * as companyActions from 'state/company/company.actions';
import { setMembership } from 'state/user/user.actions';

const Logout = () => {
  const dispatch = useDispatch();
  const { mutate } = useSWRConfig();

  React.useLayoutEffect(
    () => {
      dispatch(authActions.logout);
      dispatch(companyActions.clear);
      dispatch(setMembership(null));
      mutate(
        () => true,
        undefined,
        { revalidate: false },
      );
    },
    [dispatch, mutate],
  );

  return <Navigate to="/login" />;
};

export default Logout;

import { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';

import useSWRInfinite from 'swr/infinite';

import {
  companies as companiesServices,
} from 'services';

import { versionedApi } from 'services/network';

import { useCurrentMembership, usePermissions } from './useMemberships';

export const useCompany = (companyIdParam: number | 'mine' | null) => {
  const { selectedMembership } = useCurrentMembership();
  const id = Number(companyIdParam === 'mine' ? selectedMembership?.company?.id : companyIdParam);

  const data = useSWR<CompanyDetails>(
    selectedMembership?.id && id ? ['company', id, selectedMembership?.id] : null,
    // @ts-expect-error not typed api call
    (params: unknown[]) => companiesServices.getCompany(`/2/companies/${params[1]}`),
  );

  return {
    data,
    isSelf: companyIdParam === 'mine' || id === Number(selectedMembership?.company?.id),
    companyId: id,
  };
};

const fetcher = (url: string) => versionedApi.get(url.startsWith('/2') ? url.slice(2) : url).then(({
  // @ts-expect-error old api client automatically gives response
  'hydra:member': data,
  // @ts-expect-error same
  'hydra:view': view,
  // @ts-expect-error same
  'hydra:totalItems': totalItems,
}) => (({
  // @ts-expect-error dropdown needs these
  data: data.map(d => ({ ...d, label: d.name, value: d.name })),

  totalItems,
  nextPage: view?.['hydra:next'],
}) as {nextPage: string,
  totalItems: number, data: CompanyDetails[]}));

export const useCompanies = (params: Record<string, unknown>, {
  skip, onError, onSuccess, initialSize, allowWithoutSelectedMembership,
}: {
  skip?: boolean
  onSuccess?: (data: Awaited<ReturnType<typeof fetcher>>[], key: string) => void,
  onError?: () => void,
  initialSize?: number
  allowWithoutSelectedMembership?: boolean
} = {}) => {
  const { permissions } = usePermissions();

  const canViewCompanies = permissions?.['company.company_childs.view'] || (allowWithoutSelectedMembership && !permissions);
  const stringifiedParams = JSON.stringify(params);

  const getKey = useCallback((index: number, previousPage: {nextPage?: string}) => {
    if (!canViewCompanies) return null;
    if (previousPage) return previousPage.nextPage;
    if (index > 0) return null;

    const query = new URLSearchParams(JSON.parse(stringifiedParams));
    if (!query.get('itemsPerPage')) {
      query.set('itemsPerPage', '25');
    }
    query.forEach((v, k) => {
      if (!v) {
        query.delete(k);
      }
    });
    query.sort();
    return `/companies?${query.toString()}`;
  }, [stringifiedParams, canViewCompanies]);

  const data = useSWRInfinite(
    getKey,
    fetcher,
    {
      dedupingInterval: 5000,
      focusThrottleInterval: 10000,
      // isPaused: () => skip || false,
      ...(onSuccess && { onSuccess }),
      ...(onError && { onError }),
      initialSize: typeof initialSize === 'number' ? initialSize : 1,
    },
  );

  return {
    swr: data,
    isLoading: data.isLoading,
    data: data?.data?.flatMap(d => d.data),
    totalItems: data?.data?.[0]?.totalItems,
    loadNextPage: () => data.setSize(s => s + 1),
  };
};

export const useCompaniesForDropdown = (
  params: Record<string, unknown>,
  includeCurrentCompany?: boolean,
  skip?: boolean,
) => {
  const [currentTerm, setCurrentTerm] = useState<string>(params?.name as string || '');
  useEffect(() => {
    setCurrentTerm(params.currentTerm as string || '');
  }, [params.currentTerm]);

  const original = useCompanies({ ...params, name: currentTerm }, { skip, initialSize: 1 });

  const { selectedMembership } = useCurrentMembership();
  const currentCompany = selectedMembership?.company;

  return {
    ...original,
    loadMore: async (
      searchTerm: string,
      _: unknown,
      additional: {loaded: number} = { loaded: 0 },
    ) => {
      const canIncludeCurrentCompany = includeCurrentCompany && currentCompany && (
        !searchTerm || currentCompany.name.toLowerCase().includes(searchTerm.toLowerCase()));
      if ((searchTerm || '') !== currentTerm) {
        // Update search term
        setCurrentTerm(searchTerm);

        await new Promise(resolve => setTimeout(() => resolve(true), 100));
        return original.swr.setSize(additional.loaded + 1).then(data => {
          let result: CompanyDetails[] | undefined = data?.[additional.loaded]?.data;
          if (canIncludeCurrentCompany) {
            result = [currentCompany as unknown as CompanyDetails, ...(result || [])];
          }
          return ({
            options: result,
            hasMore: !!data?.[additional.loaded].nextPage,
            additional: { loaded: additional.loaded + 1 },
          });
        });
      }
      // request one more page
      return original.swr.setSize(additional.loaded + 1).then(data => {
        let result: CompanyDetails[] | undefined = data?.[additional.loaded]?.data;
        if (canIncludeCurrentCompany) {
          console.log('Including current company', currentCompany);
          result = [currentCompany as unknown as CompanyDetails, ...(result || [])];
        }
        return ({
          options: result,
          hasMore: !!data?.[additional.loaded].nextPage,
          additional: { loaded: additional.loaded + 1 },
        });
      });
    },
  };
};

// Autogenerated type defs here:
export interface CompanyDetails {
  '@context': string
  '@id': string
  '@type': string
  id: number
  name: string
  country: string
  city: string
  postalCode: string
  address: string
  memberships: string[]
  license: number
  logoImage: LogoImage
  reviewerCosts: boolean
  reviewCompany: boolean
  expertUserAddOn: boolean
  protected: boolean
  companyLicenses: CompanyLicense[]
  createdAt: string
  corporation: boolean
  parentCompany: ParentCompany
  relatedCorporation: string
  nibeCustomerNumber: string
  publisher: boolean
  operator: boolean
  publisherNamePerson1: string
  publisherFunctionPerson1: string
  publisherAutographPerson1: PublisherAutographPerson1
  publisherNamePerson2: string
  publisherFunctionPerson2: string
  publisherAutographPerson2: PublisherAutographPerson2
}

export interface LogoImage {
  '@id': string
  '@type': string
  id: number
  contentUrl: string
  filePath: string
  createdAt: string
  lastModifiedAt: string
}

export interface CompanyLicense {
  '@type': string
  id: number
  license: License
}

export interface License {
  '@type': string
  id: number
  code: string
  name: string
  type: string
  description: string
  canShow: boolean
  roles: Role[]
}

export interface Role {
  '@id': string
  '@type': string
  id: number
  name: string
  permissionsAllowed: any[][]
  view: string
}

export interface ParentCompany {
  '@id': string
  '@type': string
  id: number
  name: string
  memberships: string[]
  license: number
  expertUserAddOn: boolean
  protected: boolean
  companyLicenses: CompanyLicense2[]
  createdAt: string
  corporation: boolean
  parentCompany: ParentCompany2
  relatedCorporation: string
  publisher: boolean
  operator: boolean
}

export interface CompanyLicense2 {
  '@type': string
  id: number
  license: License2
}

export interface License2 {
  '@type': string
  id: number
  code: string
  name: string
  type: string
  description: string
  canShow: boolean
  roles: Role2[]
}

export interface Role2 {
  '@id': string
  '@type': string
  id: number
  name: string
  permissionsAllowed: any[][]
  view: string
}

export interface ParentCompany2 {
  '@id': string
  '@type': string
  id: number
  name: string
  memberships: string[]
  license: number
  expertUserAddOn: boolean
  protected: boolean
  companyLicenses: CompanyLicense3[]
  createdAt: string
  corporation: boolean
  publisher: boolean
  operator: boolean
}

export interface CompanyLicense3 {
  '@type': string
  id: number
  license: License3
}

export interface License3 {
  '@type': string
  id: number
  code: string
  name: string
  type: string
  description: string
  canShow: boolean
  roles: Role3[]
}

export interface Role3 {
  '@id': string
  '@type': string
  id: number
  name: string
  permissionsAllowed: any[][]
  view: string
}

export interface PublisherAutographPerson1 {
  '@id': string
  '@type': string
  id: number
  contentUrl: string
  filePath: string
  createdAt: string
  lastModifiedAt: string
}

export interface PublisherAutographPerson2 {
  '@id': string
  '@type': string
  id: number
  contentUrl: string
  filePath: string
  createdAt: string
  lastModifiedAt: string
}

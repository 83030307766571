import React from 'react';

import { useTranslation } from 'react-i18next';

import { Avatar } from 'appComponents/Avatar';
import { Dropdown } from 'appComponents/Dropdown';

import { Membership, MembershipRole } from 'types/Membership';

import * as S from './styled';
import { UserMenuAccordion } from './UserMenuAccordion';

import appsIcon from '../../../../assets/icons/apps.svg';
import logoutIcon from '../../../../assets/icons/arrow-right-top.svg';
import chevronDown from '../../../../assets/icons/chevron-down.svg';

export const UserMenu = ({
  availableMemberships,
  selectedMembership,
  goToAllMemberships,
}: {
  availableMemberships: Membership[];
  selectedMembership: Membership & {
    role: MembershipRole;
    view: string;
    value: string;
    label: unknown;
  };
  goToAllMemberships: () => void;
}) => {
  const [opened, setIsOpened] = React.useState(false);
  const withAllMembershipsButton = availableMemberships?.length > 5
  || availableMemberships.find(m => m.roles.find(r => r.view === 'Super Admin'));
  const { t } = useTranslation();
  return (
    <Dropdown
      align="right"
      handleOpen={setIsOpened}
      isOpened={opened}
      toggleComponent={(
        <S.Toggle>
          <Avatar label={selectedMembership?.view || ''} size="32px" title={selectedMembership?.company?.name} />
          <img alt="" src={chevronDown} />
        </S.Toggle>
      )}>
      <>
        <UserMenuAccordion
          availableMemberships={availableMemberships?.slice(0, 5)}
          handleClose={() => setIsOpened(false)}
        />
        <S.DividerStyled />
        {withAllMembershipsButton && (
          <S.MenuButton onClick={goToAllMemberships} type="button">
            <img alt="" src={appsIcon} />
            {t('All Memberships')}
          </S.MenuButton>
        )}
        <S.MenuBottom>
          <S.MenuLink to="/management/profile">{t('nav.My Account')}</S.MenuLink>
          <S.MenuLink to="/management/company">{t('nav.Company and settings')}</S.MenuLink>
          <S.DividerStyled color="dark" />
          <S.MenuLink color="secondary" to="/logout">
            {t('Logout')}
            <img alt="" src={logoutIcon} />
          </S.MenuLink>
        </S.MenuBottom>
      </>
    </Dropdown>
  );
};

import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { pxToRem } from 'appComponents/utils';
import alert from 'assets/alert.svg';
import SearchIcon from 'assets/search.svg';
import {
  NAV_HEIGHT,
  FormWidth,
  InputWidth,
  GREY,
  DARK_BLUE,
  paddingRows,
} from 'config/style';

export { ReadOnlyField } from 'components/Field';

export const Container = styled.div`
  background: white;
  padding: 1.875rem;
  overflow-y: auto;
  height: 100%;
  border-radius: 8px;
  max-height: calc(100% - 4rem);
`;

export const TitleContainer = styled.div`
  font-size: 0.875rem;
  padding: 1.5rem 0 0.75rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px;
  a {

    &:not([color]){
      color: ${({ theme }) => theme.palette.clay[500]};
      line-height: 24px;
    }

    &.active {
      color: ${({ theme }) => theme.palette.clay[800]};
      font-weight: 500;
    }
  }
`;

export const Bar = styled.div`
  padding: 1.5rem 0 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
`;

export const ItemWrapper = styled.div`
  margin-bottom: 2rem;

  h2 {
    margin-bottom: 8px;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
`;

export const ViewModeContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledLink = styled(NavLink)`
  font-weight: normal;
`;

export const Expand = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
`;

export const ProfileName = styled.h1`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 24px;
  padding-top: 24px;
`;

export const ProfileTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme: t }) => t.palette.clay[800]};
  margin-top: 4px;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr 0.5fr;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  padding: 1.56rem 2.5rem;
`;

export const Header = styled.p`
  font-weight: 500;
  font-size: 0.75rem;
`;

export const Data = styled.p`
  font-size: ${({ size }) => size || '.75rem'};
  color: ${({ isGrey }) => (isGrey ? '#a8a8a8' : DARK_BLUE)};
`;

export const Wrapper = styled.form``;

export const StyledContainer = styled.div`
  margin: 2.31rem 4.68rem;
  min-height: calc(100vh - 64px);
`;

export const ButtonContainer = styled.div`
  color: white;
  width: 100%;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  padding: 0.563rem 9.625rem 0.5rem 1.063rem;
  background-color: ${(props) => (props.selected ? 'black' : 'lightgray')};
  margin-top: 0.93rem;
`;

export const Section = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-top: 1.68rem;
`;

export const WrapperInput = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 0.75rem;
  margin-bottom: 8px;
  color: ${({ color, theme }) => color || theme.palette.clay[800]};
  display: block;
`;

export const LabelText = styled.h2`
  font-size: 0.675rem;
  margin-bottom: 1.25rem;
  font-weight: 600;
`;

export const SearchBar = styled.input`
  width: 21.7rem;
  color: rgba(0, 0, 0, 0.3);
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  padding: 0.61rem 11.03rem 0.61rem 1.02rem;
  background: url(${SearchIcon}) no-repeat;
  background-position: 95% 50%;
  background-image: rgba(29, 29, 29, 0.7);
  font-family: ${({ theme: t }) => t.baseFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-top: 0.55rem;

  :focus, :hover {
    border: 1px solid rgba(24, 84, 217, 0.30);
  }

  &:focus {
    outline: none;
  }
`;

export const Select = styled.select`
  margin-top: 0.58rem;
  width: 21.7rem;
  border: 1px solid #e4e4e4;
  appearance: none;
  border-radius: 0;
  padding: 0.61rem 11.03rem 0.61rem 1.02rem;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.3);
  font-family: ${({ theme: t }) => t.baseFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  &:focus {
    outline: none;
  }
`;

export const PanelContainer = styled.div`
  margin-right: "7.25rem";
`;

export const List = styled.details`
  background: #eaeaea;
  margin-top: 0.87rem;
`;

export const ListItem = styled.summary`
  padding: 0.75rem 3.62rem 1rem 2rem;
`;

export const OverviewRows = styled.div`
  overflow-y: auto;
  height: calc(100vh - (2 * ${NAV_HEIGHT}) - 4rem);
`;

export const AsideTitle = styled.h1`
  padding: 1.5rem 1.5rem 1.5rem 2rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
`;

export const AsideInfo = styled(AsideTitle)`
  font-weight: 400;
  font-size: 12px;
`;

export const AsideHeader = styled.div`
  padding: 1.5rem 1.5rem 1.5rem 2rem;
  font-size: 0.625rem;
  h1 {
    font-size: 0.875rem;
    line-height: normal;
    margin-bottom: 0;
    font-weight: 500;
  }

  div {
    font-size: 0.625rem;
    margin-bottom: 8px;
  }
`;

export const AsideLink = styled(NavLink)`
  background-color: white;
  color: inherit;
`;

export const Row = styled.div`
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  min-height: 4.7rem;
  font-size: 0.875rem;
  margin-bottom: 1.1rem;
  background: ${({ blueBackground }) => (blueBackground ? '#F6F9FB' : 'white')};
  display: flex;
  flex-direction: ${({ expand }) => (expand ? 'column' : 'row')};
  justify-content: space-between;
  align-items: stretch;
  padding: 0 1.37rem;

  a {
    width: 100%;
    align-self: center;
  }
`;

export const SimpleRow = styled.div`
  min-height: 4.7rem;
  font-size: 0.875rem;
  margin-bottom: ${({ expand }) => (expand ? '1.1rem' : '0')};;
  display: flex;
  flex-direction: ${({ expand }) => (expand ? 'column' : 'row')};
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;

  a {
    width: 100%;
    align-self: center;
  }
`;

export const RowContainer = styled.div`
  background: ${({ blueBackground }) => (blueBackground ? '#F6F9FB' : 'white')};
  min-height: 4rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  padding: 0 1.37rem;
  border-radius: 8px;
  ${props => props.border && css`
    border-radius: 8px;
    border: 1px solid var(--clay-200, #EFEEEC);
  `}

`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  gap: 12px;
  padding: 0 1.5rem 0 2rem;
  min-height: 4rem;
  margin-bottom: 48px;
`;

export const ArrowSelection = styled.img`
  margin-right: 8px;
`;

export const Details = styled.p`
  font-size: 0.875rem;
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: start;
  position: relative;
`;

export const FormActions = styled.div`
  display: flex;
  position: sticky;
  flex-wrap: wrap;
  top: 20px;
  margin-top: -50px;
  grid-column: 9 / 13;
  margin-left: auto;
  grid-row: 1;
  gap: 6px;

  > button + button {
    padding: 0;
  }
`;

export const FormNav = styled.ul`
  display: flex;
  padding: 20px 10px 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: var(--white, #FFF);
  grid-column: 1 / 3;
  min-width: 228px;
  max-width: 238px;

  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 64px;

  button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: ${pxToRem(12)};
    font-weight: 400;
    display: block;
    text-align: left;
    transition: 100ms;
  }
`;

export const FormContainer = styled.section`
  width: ${FormWidth};
  min-height: calc(100vh - 4 * ${NAV_HEIGHT});
  display: flex;
  grid-column: 3 / 10;
  grid-row: 1;

  &:last-of-type {
    padding-bottom: 40vh;
  }

  & + & {
    grid-row: 2;
  }
  > header {
    min-width: ${InputWidth};
    margin: 0 auto;
  }

  > form,> div {
    //max-width: ${InputWidth};
    max-width: 715px;
    width: 100%;
    /* display: flex;
    flex-direction: column; */
    // gap: 16px;
    padding-bottom: 2rem;
    margin: 0 auto;

    p {
      font-size: 0.75rem;
      margin-top: 4px;
    }
  }
`;

export const TitleBar = styled.h1`
  padding: 1.25rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;;
`;

export const Background = styled.main`
`;

export const Description = styled.div`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.palette.clay[500]};
  font-size: 0.75rem;
  font-weight: 100;
  max-width: 680px;
`;

export const HeaderTitle = styled.div`
  padding: 1.5rem 0 0.75rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.875rem;
  min-height: ${NAV_HEIGHT};
  position: sticky;
  top: 0;
  background-color: hsl(0,9.1%,97.8%);

  > div {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
`;

export const SectionTitile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 0.875rem;
  font-weight: 500;

  h4 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    margin-right: 10px;
  }

  legend {
    font-weight: 100;
    color: ${({ theme }) => theme.palette.clay[500]};
    font-size: 0.75rem;
  }
`;

export const SectionWrapper = styled.div`
  margin-bottom: 3.75rem;
`;

export const AlignItems = styled.div`
  display: flex;
  align-items: center;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: 0.3s;
  opacity: ${(props) => (props.isClickable ? 1 : 0.3)};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};

  &:hover {
    background: ${(props) => (props.isClickable ? GREY : 'none')};
  }

  div {
    min-width: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }
`;

export const PaddingRow = styled.div`
  padding: ${paddingRows};
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-bottom: 1px solid #e4e4e4;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NameTitle = styled.h3`
  font-size: 0.75rem;
  margin-bottom: 4px;
  font-weight: 600;
  color: ${DARK_BLUE};
`;

export const DetailsInfo = styled.p`
  font-size: 0.625rem;
  font-weight: normal;
  color: ${({ theme: t }) => t.palette.clay[500]};
  margin-bottom: 16px;
`;

export const SubText = styled.p`
  font-size: 0.75rem;
  font-weight: normal;
  color: #a8a8a8;
`;

export const AlertContainer = styled.div`
  background: #fff6d0;
  border: 1px solid #ffce01;
  background-image: url(${alert});
  background-repeat: no-repeat;
  background-position: ${({ imageRight }) => (imageRight ? '98% 50%' : '3% 50%')};
`;

export const SectionDivider = styled.div`
  :not(:last-child) {
    height: 1px;
    width: 100%;
    background: #e4e4e4;
    margin-top: -1rem;
    margin-bottom: 2rem;
  }
`;

export const Img = styled.img`
  cursor: pointer;
`;

export const GroupFields = styled.div`
`;

export const GroupFieldsHeader = styled.div`
  font-weight: 500;
  font-size: ${pxToRem(14)};
  color: #202538;
  padding: ${pxToRem(16)} 0;
`;

export const GroupFieldsDivider = styled.div`
  min-height: 1px;
  background: #e4e4e4;
  margin: 1rem 0;
`;

export const Download = styled.div`
  cursor: pointer;
  text-decoration-line: underline;
  font-size: 0.875rem;
  font-weight: normal;
  margin-top: 9px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Icon = styled.img`
  transform: rotate(90deg);
`;

export const SidebarFiltersTitle = styled.h2`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  span {
    margin-top: 4px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: normal;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

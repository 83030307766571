import styled from 'styled-components';

import { YELLOW, DARK_BLUE, footerHeight } from 'config/style';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  color: ${DARK_BLUE};
  background: white;
`;

export const InputContainer = styled.form`
  width: 60%;
  max-width: 380px;
  height: calc(100vh - ${footerHeight});
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const Wrapper = styled.div`
  background: ${YELLOW};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 4px;
`;

export const StyledLink = styled.span`
  color: ${({ theme }) => theme.palette.clay[500]};
  font-weight: normal;
  align-self: flex-start;

  a {
    font-size: 10px;
    color: inherit;
  }
`;

export const WelcomeText = styled.p`
  color: black;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  width: 390px;
  text-align: center;
`;

export const StyledError = styled.p`
  color: #ff0000;
  font-weight: normal;
  font-size: 14px;
  display: block;
`;

export const StyledImg = styled.img`
  margin-bottom: 4.18rem;
  align-self: flex-start;
`;

export const HeroImg = styled.img`
  height: 100%;
  min-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 100vh;
  object-fit: cover;
  object-position: top left;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  input {
    width: 100%;
  }
`;

export const StyledTitle = styled.h1`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 40px;
  margin-bottom: 3.125rem;
`;

export const StyledSubTitle = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 1.5rem;
`;

export const RememberWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 2rem;
  margin-top: 1rem;

  display: none;
`;

export const Text = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`;

export const Success = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
`;

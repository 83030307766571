import React, { Suspense, lazy } from 'react';

import { render } from 'react-dom';

import { Provider } from 'react-redux';

import {
  Outlet,
  Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements,
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import { PersistGate } from 'redux-persist/integration/react';

import { ThemeProvider } from 'styled-components';

import { RethinkSuiteLayers } from 'apps';
import GlobalStyles, { theme, FontGlobalStyles } from 'components/Global.styled';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { requireAuth } from 'components/requireAuth';
import { Loading } from 'components/Splash/Loading';

import { RollbarContextProvider, RollbarUserTracker } from 'context/RollbarContext';
import { SuiteLayout } from 'layout/SuiteLayout';
import Login from 'routes/Auth/Login/Login';
import Logout from 'routes/Auth/Logout/Logout';
import NotFound from 'routes/NotFound';

import { MembershipSelectorPage } from 'routes/RethinkDashboard';
import { store, persistor } from 'state';

import './i18n/i18n';

import { TokenRefreshRequestHandler } from './hooks/useTokenRefresher';
import * as serviceWorker from './serviceWorker';

// Lazy-Loaded routes
const Forgot = lazy(() => import('./routes/Auth/Forgot/Forgot'));
const Reset = lazy(() => import('./routes/Auth/Reset'));

const SuiteLayerRoutes = requireAuth(() => (
  <Routes>
    {Object.entries(RethinkSuiteLayers)
      .map(([key, { requireOneOf, entryPoint: EntryPoint, noPermissionPage }]) => (
        <Route key={key} element={<ProtectedRoute behavior="select membership" fallback={noPermissionPage} requireOneOf={requireOneOf} />}>
          <Route element={<EntryPoint />} path={`/${key}/*`} />
        </Route>
      ))}

    <Route element={<MembershipSelectorPage />} path="" />

    <Route Component={NotFound} path="*" />

  </Routes>
));

const WrapperRoute = () => (
  <>
    <ToastContainer />
    <Outlet />
  </>
);

const router = createBrowserRouter(createRoutesFromElements(
  <Route element={<WrapperRoute />}>
    <Route Component={Login} path="/login" />
    <Route Component={Logout} path="/logout" />
    <Route Component={Forgot} path="/forgot" />
    <Route Component={Reset} path="/password-reset" />
    <Route
      element={(
        <SuiteLayout>
          <RollbarUserTracker />
          <Suspense fallback={<Loading />}>
            <SuiteLayerRoutes />
          </Suspense>
        </SuiteLayout>
      )}
      path="/*"
    />

  </Route>,
));

export const RethinkSuite = (
  <>
    <FontGlobalStyles />
    <RollbarContextProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <TokenRefreshRequestHandler />

            <Suspense fallback={<Loading />}>

              <RouterProvider router={router} />

            </Suspense>

          </PersistGate>
        </Provider>
        <GlobalStyles />
      </ThemeProvider>
    </RollbarContextProvider>
  </>
);
render(RethinkSuite, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/*

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
*/
// EPD-799 unregister SW - this way everything gets fetched from the server
serviceWorker.unregister();
